import { Component } from '@angular/core';
import { AngularModule, MaterialModule } from '../../shared/modules';

@Component({
  selector: 'app-nav',
  standalone: true,
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  imports: [AngularModule, MaterialModule],
})
export class NavComponent {

  nav = [
    {
      name: 'Contact',
      path: '/contact'
    }
  ];

  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

}
