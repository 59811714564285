import { HttpInterceptorFn } from '@angular/common/http';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  // const token = localStorage.getItem(TOKEN_STORAGE);

  // if (!token || token === '') {
  //   return next(req);
  // }
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache',
  };

  // return next(reqWithHeader);
  const reqWithHeaders = req.clone({
    //     // 'Authorization': token || '',
    setHeaders: headers
  });
  return next(reqWithHeaders);

  //   return next(reqWithHeaders).pipe(
  //     tap(
  //       event => {
  //         if (event.type === 4) { // El evento 4 indica la respuesta HTTP
  //         }
  //       },
  //       error => {
  //       }
  //     )
  //   );
  // };


};