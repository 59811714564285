<section class="navPc ">

  <nav>

  


  </nav>

</section>

<section class="navMobile" [class.open]="isMenuOpen">

  <div class="menuMobile">

    <div class="logo fadeIn">
    
    </div>

  

    <div class="toggleMenu" (click)="toggleMenu()">
      @if (isMenuOpen) {
      <mat-icon class="fadeIn" fontIcon="close"></mat-icon>
      } @else {
        <mat-icon class="fadeIn" fontIcon="menu"></mat-icon>
      }
    </div>
  </div>

  <ng-container *ngIf="isMenuOpen === true">

    <nav class="sidebar fadeIn">
      
      <ul class="menuSidebar fadeIn">
        @for (i of nav; track i.name) {
        <li (click)="toggleMenu()">
          <a [routerLink]="i.path" routerLinkActive="routerLinkActive" href="{{i.path}}">{{i.name}}</a>
        </li>
        }
      </ul>
    </nav>
  </ng-container>

</section>