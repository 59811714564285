

@defer {
  @if(isMenuVisible){  
    <div class="layout" [ngClass]="isSidebar()">
      <!-- <app-sidebar /> -->

      <div class="ctnBody">
        <!-- <app-header /> -->
        <router-outlet></router-outlet>
      </div>

    </div>
    <app-footer />
  }@else {
    <router-outlet></router-outlet>
  }

} @placeholder (minimum 1000ms) {
  <app-spinner />
}
