import { Component, HostListener,  OnInit, inject, signal } from '@angular/core';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { NavigationEnd, Router } from '@angular/router';

import { AngularModule, MaterialModule } from '@shared/modules';
import { NavComponent } from "@layout/nav/nav.component";
import { FooterComponent } from "@layout/footer/footer.component";
import { SpinnerComponent } from "./shared/components/spinner/spinner.component";
import { TableService } from '@shared/components/table/table.service';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [
    AngularModule,
    MaterialModule,
    NavComponent, FooterComponent,
    SpinnerComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],

})
export class AppComponent implements OnInit {

  showGoBackTopButton = false;
  isMenuOpen: boolean = false;
  isMenuVisible: boolean = false;
  public isSpinner = signal<boolean>(true);

  // ========== INJECT DEPENDENCIES ==========
  private readonly _tableSvc = inject(TableService);

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const windowHeight = window.innerHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || 0;
    const scrollPercentage = (scrollPosition / windowHeight) * 100;

    this.showGoBackTopButton = scrollPosition >= 400;
  }

  constructor(
    private _router: Router
  ) {
    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngOnInit(): void {
    // this.isMenu();
    this.observerChangeRouter();

    this._tableSvc.spinner$.subscribe((state: any) => {
      this.isSpinner.set(state);
    });
  }

  observerChangeRouter() {
    // this._router.events.subscribe((event: any) => {
    //   if (event['urlAfterRedirects'] === '/login') {
    //     this.isMenuVisible = false;
    //   }
    //   if (event instanceof NavigationStart) {
    //     this.configMenu(event);
    //     // this.actualRoute = event.url;
    //     // this.isMenuVisible = !['/login', '/aplicativos'].includes(this.actualRoute);
    //   }
    // });
  }


  isSidebar(): { [key: string]: boolean } {
    return { 'sidebarHidden': !this.isMenuOpen };
  }

  goBackTop(): void {
    window.scrollTo(0, 0);
  }

}
