import { Routes } from '@angular/router';
// import { AuthGuard } from '@core/guards/auth-guard';

export const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    loadChildren: () => import('./auth/auth.routing').then(r => r.authRouting),
    // canActivate: [AuthGuard]
  },
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.routing').then(r => r.portalRouting),
  },
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.routing').then(r => r.panelRouting)
  },
  

  { path: '**', redirectTo: 'login', pathMatch: 'full' },

];
